import { InnerWrapper } from '@Components/container/container.styles';
import { bp, rem } from '@Utils';
import styled from 'styled-components';
import footerLogo from '@Images/logo_text.svg';
import graceBaptistLogo from '@Images/grace-baptist-logo.png';

export const Wrapper = styled.div`
	${InnerWrapper} {
		display: grid;
		padding-top: ${rem(50)};
		padding-bottom: ${rem(50)};
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto auto;
		grid-template-areas:
			'findus links services'
			'connect blank logos';
		column-gap: ${rem(40)};
		row-gap: ${rem(50)};
		font-size: ${rem(12)};
		${bp.small} {
			grid-template-columns: 1fr;
			grid-template-areas: 'findus' 'links' 'services' 'connect' 'logos';
		}
	}
`;

interface SectionProps {
	area: string;
}

export const Section = styled.div<SectionProps>`
	grid-area: ${({ area }) => area};
`;

export const ConnectLink = styled.div`
	margin-bottom: ${rem(10)};
`;

export const Icon = styled.img`
	width: ${rem(26)};
	margin-right: ${rem(10)};
	display: inline-block;
	vertical-align: middle;
`;

export const GraceChurchLogo = styled.img.attrs({
	src: footerLogo,
})`
	width: ${rem(200)};
`;

export const GraceBaptistLogo = styled.img.attrs({
	src: graceBaptistLogo,
})`
	width: ${rem(200)};
`;
