// import { CustomLink } from '@Components/customLink';
import Container from '@Components/container';
import { Scheme } from '@Utils';
import { graphql, useStaticQuery } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { RichTextField } from '@prismicio/types';
import React from 'react';
import {
	Wrapper,
	Section,
	Icon,
	ConnectLink,
	GraceChurchLogo,
	GraceBaptistLogo,
} from './footer.styles';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

interface BodySection {
	slice_type: string;
	primary: {
		section_content: { richText: RichTextField };
		section_heading: { text: string };
	};
}

interface LinkItem {
	link_url: { url: string };
	link_text: { text: string };
	link_icon: { url: string };
}

interface BodyLinks {
	slice_type: string;
	primary: {
		section_title: { text: string };
	};
	items: LinkItem[];
}

interface QueryFooter {
	allPrismicFooter: {
		nodes: {
			data: {
				body: (BodySection | BodyLinks)[];
			};
		}[];
	};
}

export const Footer = (): JSX.Element => {
	const query = useStaticQuery(graphql`
		{
			allPrismicFooter {
				nodes {
					_previewable
					data {
						body {
							... on PrismicFooterDataBodySection {
								id
								slice_type
								primary {
									section_content {
										richText
									}
									section_heading {
										text
									}
								}
							}
							... on PrismicFooterDataBodyLinks {
								id
								slice_type
								primary {
									section_title {
										text
									}
								}
								items {
									link_url {
										url
									}
									link_text {
										text
									}
									link_icon {
										url
									}
								}
							}
						}
					}
				}
			}
		}
	`);
	const { data } = useMergePrismicPreviewData(query) as { data: QueryFooter };

	const slices = data.allPrismicFooter.nodes[0].data.body;
	return (
		<Wrapper>
			<Container scheme={Scheme.primaryLight}>
				{slices.map(({ slice_type, ...other }, i) => {
					if (slice_type === 'section') {
						const bodySection = other as BodySection;
						return (
							<Section area="" key={i}>
								<h4>
									{bodySection.primary.section_heading.text}
								</h4>
								<PrismicRichText
									field={
										bodySection.primary.section_content
											.richText
									}
								/>
							</Section>
						);
					} else if (slice_type === 'links') {
						const bodyLinks = other as BodyLinks;
						return (
							<Section area="" key={i}>
								<h4>{bodyLinks.primary.section_title.text}</h4>
								{bodyLinks.items.map(
									({ link_icon, link_text, link_url }, i) => (
										<ConnectLink key={i}>
											<a href={link_url.url}>
												<Icon src={link_icon.url} />
												{link_text.text}
											</a>
										</ConnectLink>
									),
								)}
							</Section>
						);
					}
				})}
				<Section area="logos">
					<div>
						<GraceChurchLogo alt="Grace Church Didcot logo" />
					</div>
					<div>
						<GraceBaptistLogo alt="Association of Grace Baptist Churches South East logo" />
					</div>
				</Section>
			</Container>
		</Wrapper>
	);
};

export default Footer;
