import styled from 'styled-components';
import { Colors, rem } from '@Utils/styles';
import { Link } from 'gatsby';
import { Wrapper as ButtonWrapper } from '../button/button.styles';

interface WrapperProps {
	picture: string;
}

export const Wrapper = styled.div<WrapperProps>`
	height: ${rem(210)};
	width: ${rem(270)};
	background-image: url(${({ picture }) => picture});
	background-position: center center;
	background-size: cover;
	display: flex;
	align-items: flex-end;
`;

export const Description = styled.div`
	background: #00000042;
	color: ${Colors.textLight};
	padding: ${rem(15)};
	width: 100%;
`;

export const Location = styled.div`
	font-size: ${rem(14)};
	margin-top: ${rem(2)};
`;

export const Time = styled.div`
	font-size: ${rem(14)};
	margin-top: ${rem(5)};
`;

export const Title = styled.div`
	font-size: ${rem(20)};
	font-weight: 700;
	margin-bottom: ${rem(5)};
`;

export const PictureBlockContainer = styled.div`
	overflow-x: auto;
	margin: 0 ${rem(-30)} ${rem(70)};
	& + ${ButtonWrapper} {
		margin-top: ${rem(-35)};
	}
`;

export const PictureBlockInner = styled.div`
	min-width: ${rem(270 * 3 + 120)};
	display: flex;
	justify-content: space-between;
	padding: 0 ${rem(30)};
`;

export const PictureLink = styled(Link)`
	text-decoration: none;
`;
