import { bp, Colors, rem } from '@Utils/styles';
import styled from 'styled-components';

export const Circle = styled.div`
	background: ${Colors.secondary};
	color: ${Colors.textLight};
	padding: ${rem(20)};
	border-radius: 100%;
	width: ${rem(150)};
	height: ${rem(150)};
	display: inline-flex;
	align-items: center;
	text-align: center;
	margin: ${rem(-76)} ${rem(100)};
	z-index: 9;
	position: relative;
	float: right;
	font-size: ${rem(18)};
	transform: rotate(12deg);
	${bp.medium} {
		font-size: ${rem(14)};
		width: ${rem(120)};
		height: ${rem(120)};
		margin-top: ${rem(-65)};
	}
	${bp.small} {
		margin-top: ${rem(-94)};
	}
`;
