import {
	Container,
	PictureBlock,
	PictureBlockContainer,
	PictureBlockInner,
} from '@Components';
import { ContainerProps } from '@Components/container';
import { Scheme } from '@Utils';
import { PageGallerySlice } from '@Utils/types';
import React from 'react';

interface PageGalleryProps {
	heading?: string;
	items: {
		page_image: string;
		page_link: string;
		page_name: string;
		subtitle: string;
	}[];
	scheme?: Scheme;
	applyPadding?: ContainerProps['applyPadding'];
}

export const PageGallery = ({
	heading,
	items,
	scheme = Scheme.primary,
	applyPadding,
}: PageGalleryProps): JSX.Element => {
	return (
		<Container scheme={scheme} applyPadding={applyPadding}>
			{heading && <h2>{heading}</h2>}
			<PictureBlockContainer>
				<PictureBlockInner>
					{items.map(
						({ page_link, page_image, page_name, subtitle }, i) => (
							<PictureBlock
								picture={page_image}
								title={page_name}
								link={page_link}
								time={subtitle}
								key={i}
							/>
						),
					)}
				</PictureBlockInner>
			</PictureBlockContainer>
		</Container>
	);
};

export const prismicDataToPageGalleryProps = (
	data: PageGallerySlice,
): PageGalleryProps => {
	return {
		heading: data.primary.heading.text,
		items: data.items.map(
			({ page_link, page_image, page_name, subtitle }) => ({
				page_link: page_link.url,
				page_image: page_image.url,
				page_name: page_name.text,
				subtitle: subtitle.text,
			}),
		),
	};
};
