import React from 'react';
import { Link } from 'gatsby';

export const linkResolver = (doc: { type: string; uid: string }) => {
	if (doc.type === 'basic_page') {
		return `/${doc.uid}`;
	}
	return '/';
};

export const CustomLink = (
	type: any,
	element: any,
	content: any,
): JSX.Element | null => {
	if (element.data.link_type === 'Document') {
		return (
			<Link to={linkResolver(element.data)} key={element.data.id}>
				{content}
			</Link>
		);
	}

	if (element.data.link_type === 'Web') {
		return (
			<a id={element.data.id} href={element.data.url}>
				{content}
			</a>
		);
	}
	return null;
};
