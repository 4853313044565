import React, { ReactNode } from 'react';
import { Wrapper } from './button.styles';

export interface ButtonProps {
	children: ReactNode;
	to: string;
}

export const Button = ({ children, to }: ButtonProps): JSX.Element => {
	return <Wrapper to={to}>{children}</Wrapper>;
};

export default Button;
