import React from 'react';
import { Circle } from './callout.styles';

interface CalloutProps {
	text: string;
}

export const Callout = ({ text }: CalloutProps): JSX.Element => {
	return <Circle>{text}</Circle>;
};
