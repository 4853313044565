import Container from '@Components/container';
import { bp, Colors, Fonts, rem } from '@Utils';
import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
	height: ${rem(600)};
	position: relative;
	overflow: hidden;
	&:before {
		content: '';
		background: ${Colors.primary}80;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 2;
	}
	${bp.medium} {
		height: ${rem(340)};
	}
`;

const animation = keyframes`
	0% {
		opacity: 0;
		transform: scale(3);
	}
	25% {
		opacity: 1;
		transform:scale(1);
	}
	75% {
		opacity: 1;
		transform:scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(3);
	}
`;

export const ImageWrapper = styled.div`
	animation: ${animation} 15s infinite;
	position: relative;
	height: 100%;
`;

export const Image = styled.img`
	position: relative;
	top: 25%;
	left: 50%;
	transform: translate(-50%, -25%);
	min-width: ${rem(520)};
`;

export const PreLoad = styled.img`
	height: 1px;
	width: 1px;
	opacity: 0;
`;

export const Text = styled(Container)`
	font-family: ${Fonts.robotoSlab};
	font-size: ${rem(48)};
	color: ${Colors.textLight};
	align-items: center;
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	${bp.medium} {
		padding-top: ${rem(80)};
		font-size: ${rem(36)};
	}
`;
