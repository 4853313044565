import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

interface SeoProps {
	title?: string;
	description?: string;
}

const SEO = ({ title, description }: SeoProps): JSX.Element => {
	const { pathname } = useLocation();
	const { site } = useStaticQuery(query);

	const { defaultTitle, titleTemplate, defaultDescription, siteUrl } =
		site.siteMetadata;

	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		url: `${siteUrl}${pathname}`,
	};

	return (
		<Helmet title={seo.title} titleTemplate={titleTemplate}>
			<meta name="description" content={seo.description} />
			<meta
				name="google-site-verification"
				content="WCJ-BdNMVrFKy9aBlLCBqIPRC4-kQkqCB83jzUD_4Tw"
			/>

			{seo.url && <meta property="og:url" content={seo.url} />}

			{seo.title && <meta property="og:title" content={seo.title} />}

			{seo.description && (
				<meta property="og:description" content={seo.description} />
			)}

			{seo.title && <meta name="twitter:title" content={seo.title} />}

			{seo.description && (
				<meta name="twitter:description" content={seo.description} />
			)}
		</Helmet>
	);
};

export default SEO;

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				defaultTitle: title
				titleTemplate
				defaultDescription: description
				siteUrl: url
			}
		}
	}
`;
