import React, { ReactChild } from 'react';
import { Wrapper, ImageWrapper, Image, Text, PreLoad } from './banner.styles';

import { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

interface BannerProps {
	children: ReactChild;
}

interface QueryBanner {
	allPrismicBanner: {
		nodes: {
			data: {
				images: {
					image: {
						url: string;
					};
				}[];
			};
		}[];
	};
}

export const Banner = ({ children }: BannerProps): JSX.Element => {
	const data: QueryBanner = useStaticQuery(graphql`
		query Banner {
			allPrismicBanner {
				nodes {
					data {
						images {
							image {
								url
							}
						}
					}
				}
			}
		}
	`);
	const pictures = data?.allPrismicBanner?.nodes[0]?.data?.images?.map(
		({ image: { url } }) => url,
	);
	const [currentIndex, setIndex] = useState(0);
	const onAnimationIteration = () => {
		if (currentIndex + 1 >= pictures.length) {
			setIndex(0);
		} else {
			setIndex(currentIndex + 1);
		}
	};
	return (
		<Wrapper>
			<ImageWrapper onAnimationIteration={onAnimationIteration}>
				<Image src={pictures[currentIndex]} />
			</ImageWrapper>
			{pictures.map((pic, i) => (
				<PreLoad src={pic} key={i} />
			))}
			<Text>{children}</Text>
		</Wrapper>
	);
};
