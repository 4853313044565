import '@fontsource/open-sans';
import '@fontsource/open-sans/700.css';
import '@fontsource/roboto-slab/700.css';
import React, { ReactNode, useState } from 'react';
import {
	GlobalStyle,
	MenuBar,
	Wrapper,
	Logo,
	BannerStatic,
	MenuButton,
} from './layout.styles';
import { Menu } from '@Components/menu';
import { Footer } from '@Components/footer';
import { Link } from 'gatsby';
import { Banner } from '@Components/banner/banner';

export interface LayoutProps {
	children: ReactNode;
	location: {
		pathname: string;
	};
}

export const Layout = ({ children, location }: LayoutProps): JSX.Element => {
	const [menuOpen, setMenuOpen] = useState(false);
	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};
	const closeMenu = () => {
		setMenuOpen(false);
	};
	return (
		<Wrapper menuOpen={menuOpen}>
			<GlobalStyle />
			<MenuBar>
				<Link to="/">
					<Logo />
				</Link>
				<MenuButton onClick={toggleMenu} menuOpen={menuOpen}>
					<div />
				</MenuButton>
				<Menu open={menuOpen} onNavigate={closeMenu} />
			</MenuBar>
			{location.pathname === '/' ? (
				<Banner>Bringing Christian hope and good news to Didcot</Banner>
			) : (
				<BannerStatic small />
			)}

			{children}
			<Footer />
		</Wrapper>
	);
};

export default Layout;
