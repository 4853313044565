import React, { ReactNode } from 'react';
import { Scheme } from '@Utils/styles';
import { InnerWrapper, Wrapper, WrapperProps } from './container.styles';

export type ContainerProps = WrapperProps & {
	children?: ReactNode;
	className?: string;
	scheme?: Scheme;
};

export const Container = ({
	children,
	...props
}: ContainerProps): JSX.Element => {
	return (
		<Wrapper {...props}>
			<InnerWrapper>{children}</InnerWrapper>
		</Wrapper>
	);
};

export default Container;
