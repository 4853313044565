export enum Scheme {
	primary,
	primaryLight,
	dark,
	light,
	map,
}

export enum Colors {
	dark = '#3f3b3b',
	primary = '#0f676a',
	primaryLight = '#54a0a2',
	secondary = '#8b64b3',
	textLight = '#fff',
	textDark = '#000',
}

export enum Fonts {
	openSans = "'Open Sans', sans-serif",
	robotoSlab = "'Roboto Slab', serif",
}

export const rem = (px: number, basePx = 16): string => `${px / basePx}rem`;

export const bp = {
	small: `@media (max-width: ${rem(600)})`,
	medium: `@media (max-width: ${rem(1000)})`,
};
