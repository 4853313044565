import styled, { createGlobalStyle, css } from 'styled-components';
import { bp, Colors, Fonts, rem } from '@Utils/styles';
import logoImage from '@Images/logo_circle.svg';
import bannerImage from '@Images/banner_image.jpg';
import { Container } from '@Components/container';

export const GlobalStyle = createGlobalStyle`
	* {
		box-sizing: border-box;
	}

	html {
		padding: 0;
		margin: 0;
	}

	body {
		font: normal 100%/normal ${Fonts.openSans};
		padding: 0;
		margin: 0;
	}

	h1, h2 {
		font-size: ${rem(40)};
		text-align: center;
		${bp.small} {
			font-size: ${rem(36)};
			margin-top: 0;
		}
	}

	h3 {
		font-size: ${rem(26)};
	}

	h4 {
		font-size: ${rem(18)};
		margin: 0 0 ${rem(10)};
	}

	a {
		color: inherit;
	}

	img {
		max-width: 100%;
	}
`;

interface WrapperProps {
	menuOpen: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
	${({ menuOpen }) =>
		menuOpen &&
		css`
			height: 100vh;
			overflow: hidden;
		`}
`;

export const MenuBar = styled(Container)`
	background: ${Colors.dark};
	color: ${Colors.textLight};
	height: ${rem(120)};
	position: relative;
	z-index: 10;
	${bp.small} {
		height: ${rem(80)};
	}
	> div {
		display: flex;
		justify-content: space-between;
	}
`;

export const Logo = styled.img.attrs({ src: logoImage })`
	width: ${rem(230)};
	height: ${rem(230)};
	position: relative;
	top: ${rem(-30)};
	${bp.medium} {
		width: ${rem(160)};
		height: ${rem(160)};
	}
`;

interface BannerProps {
	small?: boolean;
}

export const BannerStatic = styled(Container)<BannerProps>`
	position: relative;
	background-image: url(${bannerImage});
	background-position: center center;
	background-size: cover;
	height: ${({ small }) => (small ? rem(200) : rem(600))};
	font-family: ${Fonts.robotoSlab};
	font-size: ${rem(48)};
	color: ${Colors.textLight};
	align-items: center;
	text-align: center;
	${bp.small} {
		${({ small }) =>
			!small &&
			css`
				height: ${rem(340)};
			`}
		font-size: ${rem(36)};
		padding-top: ${rem(80)};
	}
`;

interface MenuButtonProps {
	menuOpen: boolean;
}

export const MenuButton = styled.button<MenuButtonProps>`
	position: relative;
	width: ${rem(35)};
	height: ${rem(26)};
	background: none;
	border: none;
	position: relative;
	z-index: 20;
	cursor: pointer;
	&:before,
	&:after,
	div {
		content: '';
		display: block;
		width: 100%;
		height: ${rem(3)};
		position: absolute;
		background: ${Colors.textLight};
		transform-origin: left;
	}
	&:before,
	&:after {
		transition: 0.5s all ease-in-out;
	}
	&:before {
		top: 0;
	}
	&:after {
		bottom: 0;
	}
	div {
		top: 50%;
		margin-top: ${rem(-1)};
	}
	${({ menuOpen }) =>
		menuOpen &&
		css`
			&:before {
				transform: rotate(45deg);
				width: ${rem(32.5)};
			}
			&:after {
				transform: rotate(-45deg);
				width: ${rem(32.5)};
			}
			div {
				opacity: 0;
			}
		`}
`;
