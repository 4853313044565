/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Layout from './src/components/layout';
import {
	PrismicPreviewProvider,
	componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';
import { PrismicProvider } from '@prismicio/react';
import { Link } from 'gatsby';
import { linkResolver } from './src/components/customLink';

import HomeTemplate from './src/pages/index';
import BasicPageTemplate from './src/pages/{PrismicBasicPage.uid}';

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
	return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
	<PrismicPreviewProvider
		repositoryConfigs={[
			{
				repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
				linkResolver,
				componentResolver: componentResolverFromMap({
					home: HomeTemplate,
					basic_page: BasicPageTemplate,
				}),
			},
		]}
	>
		<PrismicProvider
			linkResolver={linkResolver}
			internalLinkComponent={(props) => {
				console.log('internalLinkComponent', props);
				return <Link to={props.href} {...props} />;
			}}
			externalComponent={(props) => {
				console.log('externalComponent', props);
				return <a className="external" {...props} />;
			}}
		>
			{element}
		</PrismicProvider>
	</PrismicPreviewProvider>
);
