import styled, { css } from 'styled-components';
import { bp, Colors, rem, Scheme } from '@Utils/styles';
import map from '@Images/map.png';

export const InnerWrapper = styled.div`
	max-width: ${rem(900)};
	width: 100%;
`;

export interface WrapperProps {
	scheme?: Scheme;
	applyPadding?: boolean | 'top' | 'bottom';
	backgroundImage?: string;
	align?: string;
	textColor?: string;
	backgroundShade?: string;
	backgroundShadeOpacity?: string;
}

const padding = (applyPadding: WrapperProps['applyPadding']): string => {
	switch (applyPadding) {
		case 'top':
			return `${rem(50)} ${rem(50)} 0`;
		case 'bottom':
			return `0 ${rem(50)} ${rem(50)}`;
		case false:
			return `0 ${rem(50)}`;
		default:
			return `${rem(50)}`;
	}
};

export const Wrapper = styled.div<WrapperProps>`
	width: 100%;
	display: flex;
	padding: ${({ applyPadding }) => padding(applyPadding)};
	justify-content: center;
	${bp.small} {
		padding: ${rem(30)};
	}
	${({
		backgroundImage,
		textColor,
		backgroundShade,
		backgroundShadeOpacity,
		align,
	}) =>
		backgroundImage &&
		css`
			position: relative;
			background-image: url(${backgroundImage});
			background-position: center center;
			background-size: cover;
			color: ${textColor || Colors.textLight};
			text-align: ${align ?? 'left'};
			&:before {
				content: '';
				position: absolute;
				top: 0;
				display: block;
				width: 100%;
				height: 100%;
				background: ${backgroundShade ?? '#000000'};
				opacity: ${backgroundShadeOpacity ?? 0.6};
			}
			${InnerWrapper} {
				position: relative;
			}
		`};
	${({ scheme }) =>
		scheme === Scheme.primary &&
		css`
			background: ${Colors.primary};
			color: ${Colors.textLight};
		`}
	${({ scheme }) =>
		scheme === Scheme.primaryLight &&
		css`
			background: ${Colors.primaryLight};
			color: ${Colors.textDark};
		`}
	${({ scheme }) =>
		scheme === Scheme.dark &&
		css`
			background: ${Colors.dark};
			color: ${Colors.textLight};
		`}
	${({ scheme }) =>
		scheme === Scheme.map &&
		css`
			background: ${Colors.dark} url(${map});
			background-position: center center;
			background-size: cover;
			color: ${Colors.textLight};
			min-height: ${rem(400)};
			${bp.small} {
				min-height: ${rem(300)};
			}
			${InnerWrapper} {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		`}
`;
