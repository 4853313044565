import React from 'react';
import {
	Description,
	Location,
	Time,
	Title,
	Wrapper,
	PictureLink,
} from './pictureBlock.styles';

export interface PictureBlockProps {
	picture: string;
	title: string;
	time?: string;
	location?: string;
	link?: string;
}

export const PictureBlock = ({
	picture,
	title,
	time,
	location,
	link,
}: PictureBlockProps): JSX.Element => {
	const block = (
		<Wrapper picture={picture}>
			<Description>
				<Title>{title}</Title>
				<Time>{time}</Time>
				<Location>{location}</Location>
			</Description>
		</Wrapper>
	);
	return link ? <PictureLink to={link}>{block}</PictureLink> : block;
};

export default PictureBlock;
