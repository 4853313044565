import { graphql } from 'gatsby';
import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { RichTextField } from '@prismicio/types';
import {
	Container,
	PageGallery,
	prismicDataToPageGalleryProps,
} from '@Components';
import { PageGallerySlice } from '@Utils/types';
import { Scheme } from '@Utils';
import SEO from '@Components/seo/seo';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

interface BasicPage {
	data: {
		prismicBasicPage: {
			data: {
				page_title: {
					richText: RichTextField;
					text: string;
				};
				content: {
					richText: RichTextField;
				};
				body: PageGallerySlice[];
			};
		};
	};
}

export const BasicPage = ({ data }: BasicPage): JSX.Element => {
	const document = data?.prismicBasicPage?.data;
	const slices = document.body;
	return (
		<>
			<SEO title={document.page_title.text} />
			<Container>
				<PrismicRichText field={document.page_title.richText} />
				<PrismicRichText field={document.content.richText} />
			</Container>
			{slices.map(({ slice_type, ...other }, i) => {
				if (slice_type === 'page_gallery') {
					const props = prismicDataToPageGalleryProps(
						other as PageGallerySlice,
					);
					return (
						<PageGallery
							{...props}
							scheme={Scheme.light}
							key={i}
							applyPadding="bottom"
						/>
					);
				}
			})}
		</>
	);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default withPrismicPreview(BasicPage);

export const query = graphql`
	query ($uid: String) {
		prismicBasicPage(uid: { eq: $uid }) {
			_previewable
			data {
				content {
					richText
				}
				page_title {
					richText
					text
				}
				body {
					... on PrismicBasicPageDataBodyPageGallery {
						primary {
							heading {
								text
							}
						}
						items {
							page_image {
								url
							}
							page_link {
								url
							}
							page_name {
								text
							}
							subtitle {
								text
							}
						}
						slice_type
					}
				}
			}
		}
	}
`;
