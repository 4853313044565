import React, { ReactElement } from 'react';
import {
	Button,
	Container,
	PageGallery,
	prismicDataToPageGalleryProps,
} from '@Components';
import { Scheme } from '@Utils/styles';
import { graphql } from 'gatsby';
import { MapSlice, PageGallerySlice, ContentSlice } from '@Utils/types';
import SEO from '@Components/seo/seo';
import { PrismicRichText } from '@prismicio/react';
import { Callout } from '@Components/callout';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

interface node {
	node: {
		data: {
			about_us: {
				text: string;
			};
			body: PageGallerySlice[] | MapSlice[] | ContentSlice[];
		};
	};
}

interface IndexProps {
	data: {
		allPrismicHome: {
			edges: node[];
		};
	};
}

const Index = ({ data }: IndexProps): ReactElement => {
	const document = data?.allPrismicHome?.edges?.[0]?.node?.data;
	const slices = document.body;
	return (
		<>
			<SEO />
			<Container applyPadding={false}>
				<Callout text="Join us Sundays @10:30am" />
			</Container>
			<Container>
				<h2>About us</h2>
				<p>{document?.about_us?.text}</p>
				<Button to="/about-us">Find out more</Button>
			</Container>
			{slices.map(({ slice_type, ...other }, i) => {
				if (slice_type === 'page_gallery') {
					const props = prismicDataToPageGalleryProps(
						other as PageGallerySlice,
					);
					return <PageGallery {...props} key={i} />;
				} else if (slice_type === 'map') {
					const slice = (other as MapSlice).primary;
					return (
						<Container scheme={Scheme.map} key={i}>
							<h2>{slice.heading.text}</h2>
							<Button to={slice.page_link.url}>
								{slice.link_label.text}
							</Button>
						</Container>
					);
				} else if (slice_type === 'content') {
					const slice = (other as ContentSlice).primary;
					return (
						<Container
							key={i}
							backgroundImage={slice.background_image.url}
							align={slice.align}
							textColor={slice.text_color}
							backgroundShade={slice.background_shade}
							backgroundShadeOpacity={
								slice.background_shade_opacity
							}
						>
							{slice.heading.text && (
								<h2>{slice.heading.text}</h2>
							)}
							<PrismicRichText field={slice.content.richText} />
							{slice.link_label.text && (
								<Button to={slice.page_link.url}>
									{slice.link_label.text}
								</Button>
							)}
						</Container>
					);
				}
			})}
		</>
	);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default withPrismicPreview(Index);

export const query = graphql`
	query Index {
		allPrismicHome {
			edges {
				node {
					_previewable
					data {
						about_us {
							text
						}
						body {
							... on PrismicHomeDataBodyPageGallery {
								id
								primary {
									heading {
										text
									}
								}
								items {
									page_image {
										url
									}
									page_link {
										url
									}
									page_name {
										text
									}
									subtitle {
										text
									}
								}
								slice_type
							}
							... on PrismicHomeDataBodyMap {
								id
								primary {
									heading {
										text
									}
									link_label {
										text
									}
									page_link {
										url
									}
								}
								slice_type
							}
							... on PrismicHomeDataBodyContent {
								id
								primary {
									background_image {
										url
									}
									content {
										richText
									}
									heading {
										text
									}
									link_label {
										text
									}
									page_link {
										url
									}
									text_color
									background_shade_opacity
									background_shade
									align
								}
								slice_type
							}
						}
					}
				}
			}
		}
	}
`;
