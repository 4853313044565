import React from 'react';
import { Scheme } from '@Utils/styles';
import Container from '@Components/container';
import { Wrapper, MenuList } from './menu.styles';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { DocumentLink, RichText } from '@Utils/types';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

export interface MenuProps {
	open: boolean;
	onNavigate: () => void;
}

interface SubNavItem {
	sub_nav_link: DocumentLink;
	sub_nav_link_label: RichText;
}

interface MenuNavItem {
	primary: {
		link: DocumentLink;
		label: RichText;
	};
	items: SubNavItem[];
}

interface QueryMenu {
	allPrismicMenu: {
		nodes: {
			data: {
				nav: MenuNavItem[];
			};
		}[];
	};
}

export const Menu = ({ open, onNavigate }: MenuProps): JSX.Element => {
	const query = useStaticQuery(graphql`
		query Menu {
			allPrismicMenu {
				nodes {
					_previewable
					data {
						nav {
							... on PrismicMenuDataNavNavItem {
								items {
									sub_nav_link {
										url
										link_type
										uid
										type
									}
									sub_nav_link_label {
										text
									}
								}
								primary {
									link {
										uid
										link_type
										url
										type
									}
									label {
										text
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const { data } = useMergePrismicPreviewData(query) as { data: QueryMenu };
	const nav = data?.allPrismicMenu?.nodes?.[0]?.data?.nav;
	return (
		<Wrapper open={open}>
			<Container scheme={Scheme.primary} className="menu-container">
				<MenuList>
					{nav.map(({ primary, items }) => (
						<li key={primary.link.uid}>
							<Link to={primary.link.url} onClick={onNavigate}>
								{primary.label.text}
							</Link>
							{items &&
								items.length &&
								items[0].sub_nav_link_label && (
									<ul>
										{items.map(
											({
												sub_nav_link_label,
												sub_nav_link,
											}) => (
												<li key={sub_nav_link.uid}>
													<Link
														to={sub_nav_link.url}
														onClick={onNavigate}
													>
														{
															sub_nav_link_label.text
														}
													</Link>
												</li>
											),
										)}
									</ul>
								)}
						</li>
					))}
				</MenuList>
			</Container>
		</Wrapper>
	);
};

export default Menu;
