import styled from 'styled-components';
import { bp, Fonts, rem } from '@Utils/styles';
import { Link } from 'gatsby';

export const Wrapper = styled(Link)`
	font-family: ${Fonts.robotoSlab};
	padding: ${rem(10)} ${rem(15)};
	text-decoration: none;
	color: inherit;
	border: 1px solid;
	margin: ${rem(35)} auto;
	display: table;
	${bp.small} {
		margin-bottom: ${rem(10)};
	}
`;
